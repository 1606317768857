import axios from 'axios'
import qs from 'qs'
import {DateTime} from "luxon";
import Vue from 'vue'

export default {
	namespaced: true,
	state: {
		promoCode: "",
		promoLink: "",
		transactionHistory: [],
		transactionHistoryPageCount: 1,
		ordersCurrentPage: 1,
		msg: []
	},
	mutations: {
		setPromoCode(state, code)
		{
			state.promoCode = code;
		},
		setPromoLink(state, link)
		{
			state.promoLink = link;
		},
		setOrdersCurrentPage(state, number)
		{
			state.ordersCurrentPage = number;
		},
		setHistory(state, data)
		{
			state.transactionHistory = data.history;
			state.transactionHistoryPageCount = data.pageCount;
			state.msg = data.msg
		},
	},
	getters: {},
	actions: {
		async loadLoyaltyInfo({rootState, commit})
		{
			let data = qs.stringify({'restId': rootState.currentOwnerRestaurant});
			let resp = await axios.post('/personal/getPromoInfo/', data);
			commit('setPromoCode', resp.data.promoCode);
			commit('setPromoLink', resp.data.promoLink);
		},
		async loadHistory({rootState, commit}, payload = {page: 1, sort: 'date', desc: true})
		{
			payload.restId = rootState.currentOwnerRestaurant;
			let resp = await axios.post('/personal/getHistory/', qs.stringify(payload));
			commit('setHistory', resp.data);
		},
		async updateProfileInfo({rootState, commit}, data)
		{
			const params = qs.stringify(data);
			return await axios.post('/personal/updateProfileInfo/', params);
		}
	}
}