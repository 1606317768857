'use strict'
import qs from 'qs'
import axios from 'axios';
import {notification} from "@/utils";

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

export default {
	state:
		{
			authFormPopup: false,
			navigationPopup: false,
			authPhone: '',
			authStep: 1,
			promocode: '',
			paymentInfo: {},
		},
	getters:
		{
			getPaymentInfo(state)
			{
				return state.paymentInfo;
			},
			getAuthFormPopup(state)
			{
				return state.authFormPopup;
			},
			getNavigationPopup(state)
			{
				return state.navigationPopup;
			},
			getAuthPhone(state)
			{
				return state.authPhone;
			},
			getAuthStep(state)
			{
				return state.authStep;
			}
		},
	mutations:
		{
			setAuthStep(state, step)
			{
				state.authStep = step;
			},
			setAuthFormPopup(state, boolean)
			{
				state.authFormPopup = boolean;
			},
			openPhoneAuthPopup(state, args)
			{
				if (args?.phone) state.authPhone = args.phone;

				state.authStep = args?.step || 1;

				state.authFormPopup = true;
			},
			setNavigationPopup(state, boolean)
			{
				state.navigationPopup = boolean;
			},
			setPromocode(state, promocode)
			{
				state.promocode = promocode;
			},
			setPaymentInfo(state, data)
			{
				state.paymentInfo = data;
			}
		},
	actions:
		{
			closeAuthPopup({commit})
			{
				commit('setAuthFormPopup', false);
				commit('order/setOrderInProcess', false);
			},
			async getPaymentInfo({commit})
			{
				let response = await axios.get('/auth/getPaymentInfo');
				commit('setPaymentInfo', response.data);
				return response.data;
			},
			async register({state, commit}, incomingData)
			{
				try
				{
					const params = qs.stringify(incomingData);

					const {data} = await axios({
						data: params,
						method: 'post',
						url: '/auth/register/',
					});

					if (!data.success) return {success: false, errors: data.errors};

					commit('openPhoneAuthPopup', {phone: incomingData.phone, step: 2});

					return {success: true};
				} catch (error)
				{
					notification({title: 'Что-то пошло не так', type: 'error'});
				}
			},

			/**
			 * проверяет, зарегистрирован ли пользователь по номеру/почте
			 */
			async checkUserRegister({state, commit, rootGetters, dispatch}, userData)
			{
				let result = await axios({
					data: qs.stringify({phone: userData.phone, email: userData.email}),
					method: 'post',
					url: '/auth/checkUserRegister/',
				});
				return result.data;
			},

			async changeUserEmail({state, commit}, data)
			{
				let formData = qs.stringify(data);

				const result = await axios({
					data: formData,
					method: 'post',
					url: '/auth/changeUserEmail/',
				});
				return result;
			},
			async sendSmsForChangePhone({state, commit}, data)
			{
				let formData = qs.stringify(data);

				const result = await axios({
					data: formData,
					method: 'post',
					url: '/auth/sendSmsForChangePhone/',
				});
				return result;
			},
			async confirmPhone({state, commit}, data)
			{
				let formData = qs.stringify(data);
				const result = await axios({
					data: formData,
					method: 'post',
					url: '/auth/confirmPhone/',
				});
				return result;
			},
			async loginBySmsCode({state, commit}, data)
			{
				let formData = qs.stringify(data);
				const result = await axios({
					data: formData,
					method: 'post',
					url: '/auth/loginBySmsCode/',
				});
				return result;
			},
			async approveTmpUserPhone({state, commit}, data)
			{
				let formData = qs.stringify(data);

				const result = await axios({
					data: formData,
					method: 'post',
					url: '/auth/approveTmpUserPhone/',
				});
				return result;
			},
			async sendSmsCode({state, commit}, data)
			{
				const formData = qs.stringify(data);

				return await axios({
					data: formData,
					method: 'post',
					url: '/auth/sendSmsCode/',
				});
			},
			async authorization({state, commit}, data)
			{
				const formData = qs.stringify(data);

				return await axios({
					data: formData,
					method: 'post',
					url: '/auth/',
				});
			},
		}
}
