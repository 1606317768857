import {TIME_RANGES, DAYS} from "@/constants";

/**
 * Заменяет дефисы, длинные тире, короткие тире и минусы в данном тексте на дефис.
 *
 * @param {string} text - Текст, в котором нужно заменить дефисы.
 * @return {string} Текст с замененными дефисами.
 */
export const replaceHyphens = (text) =>
{
	if (!text || !text.trim()) return '';

	return text.replace(/[-–—−]/g, '-');
}

/**
 * Добавляет тысячный разделитель к заданному значению.
 *
 * @param {any} text - Значение, к которому нужно добавить тысячный разделитель.
 * @return {string} Значение с добавленным тысячным разделителем.
 */
export const addThousandSeparator = (text) =>
{
	if (!text || !text.toString()) return '';

	return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

/**
 * Преобразует текст из вида "8:00 - 11:00" в "с 8:00 до 11:00".
 *
 * @param {string} text - Текст, содержащий временной интервал для преобразования.
 * @return {string} Отформатированная строка временного интервала.
 */
export const convertTimeInterval = (text) =>
{
	if (!text || !text.trim()) return '';

	const replaceHyphensText = replaceHyphens(text);

	const [start, end] = replaceHyphensText.split('-').map(time => time.trim());

	return `c ${start} ${end && `до ${end}`}`
}

/**
 * Преобразует текст из вида "today" или "tomorrow" в "14.05.2024" или "15.05.2024".
 *
 * @param {string} text - Текст, который нужно преобразовать в дату.
 * @return {string} Строка даты в формате русского языка.
 */
export const convertTextToDate = (text) =>
{
	if (!text || !text.trim()) return '';

	if (text === 'today') return new Date().toLocaleDateString('ru');

	if (text === 'tomorrow')
	{
		const tomorrow = new Date();
		tomorrow.setDate(new Date().getDate() + 1);
		return tomorrow.toLocaleDateString('ru');
	}
}

/**
 * Преобразует текст из вида "14.05.2024" или "15.05.2024" в "today" или "tomorrow".
 *
 * @param {string} date - Строка даты в формате русского языка.
 * @return {string} Текст "today" или "tomorrow".
 */
export const convertDateToText = (date) =>
{
	if (!date || !date.trim()) return '';

	const today = new Date().toLocaleDateString('ru');

	if (date === today) return 'today';

	return 'tomorrow';
}

/**
 * Преобразует информацию о заказе из вида "lunch" "29.06.2024" "08:00 - 08:15" в "Обед завтра c 08:00 до 08:15".
 *
 * @param {string} timeRange - Временной интервал заказа (lunch, dinner, breakfast).
 * @param {string} day - День заказа (28.06.2024, 29.06.2024 и т.д.).
 * @param {string} plannedDeliveryTime (08:00 - 08:15, 08:00 и т.д.) - Планируемое время доставки.
 * @return {string} Отформатированная текстовая строка, представляющая информацию о заказе корзины.
 */
export const convertCartOrderInfoToText = ({timeRange, day, plannedDeliveryTime}) =>
{
	//В случае, если день заказа пришел в формате "2024-06-28", форматируем его в "28.06.2024"
	if (day && day.includes('-')) day = formatDate(day);

	const infoTimeRange = TIME_RANGES[timeRange] || '';
	const infoDay = DAYS[convertDateToText(day)]?.toLowerCase() || '';
	const interval = convertTimeInterval(plannedDeliveryTime) || '';

	return `${infoTimeRange} ${infoDay} ${interval}`;
}

/**
 * Форматирует строку даты из "2024-05-23" в "23.05.2024".
 *
 * @param {string} date - Строка даты для форматирования.
 * @return {string} Отформатированная строка даты.
 */
export const formatDate = (date) =>
{
	if (!date || !date.trim()) return '';

	return date.replace(/-/g, ".").split(".").reverse().join(".");
}